
import { computed, defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import TimeTable from "@/costumComponents/dashboard/Timetable.vue";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import JwtService from "@/core/services/JwtService";
import MonthChart from "@/costumComponents/dashboard/monthChart.vue";
import SubjectChart from "@/costumComponents/dashboard/subjectChart.vue";
import AdvisorMonthChart from "@/costumComponents/dashboard/AdvisorMonthChart.vue";
import AdvisorSubjectChart from "@/costumComponents/dashboard/AdvisorSubjectChart.vue";
import moment from "moment";

interface AdvisorExercise {
  _id: string;
  title: string;
  createdAt: string;
  module: string;
  validated: boolean;
  validationDate: string;
}

interface AdvisorCourse {
  _id: string;
  title: string;
  createdAt: string;
  module: string;
  validated: boolean;
  validationDate: string;
}

export default defineComponent({
  name: "dashboard",
  components: {
    TimeTable,
    MonthChart,
    SubjectChart,
    AdvisorMonthChart,
    AdvisorSubjectChart,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const classroomsData = ref([]);
    const filteredClassroomsData = ref([]);
    const apiUrl = ref<string>(store.getters.serverConfigUrl.base_url + "/");

    const user = store.getters.currentUser;
    const trimester = JwtService.getTrimester();
    const search = ref("");

    const hasTeacherAccess = computed(() => store.getters.hasTeacherAccess);
    const hasAdvisorAccess = computed(() => store.getters.hasAdvisorAccess);

    const currentUser = computed(() => store.getters.currentUser);

    const totalHours = ref(0);
    const today = ref(0);
    const tomorrow = ref(0);
    const exercises = ref([]);
    const courses = ref([]);
    const advisorExercises = ref<AdvisorExercise[]>([]);
    const advisorCourses = ref<AdvisorCourse[]>([]);
    const loadedCharts = ref(false);
    const loadedAdvisorCharts = ref(false);

    const checked = ref(false);
    const meetings = ref([]);
    const loading = ref(true);

    onMounted(async () => {
      setCurrentPageTitle(t("dashboard.dashboard"));
      ApiService.setHeader();

      if (hasTeacherAccess.value) {
        ApiService.post("/teacher/classRommDetails", {
          query: {
            teacher: store.getters.currentUser._id,
            trimester: trimester,
          },
        })
          .then(({ data }) => {
            classroomsData.value = data;
            filteredClassroomsData.value = data;
            loading.value = false;
          })
          .catch((e) => console.log(e));

        ApiService.post("/schedules/filter", {
          query: {
            teacher: user._id,
            status: { $ne: "inactive" },
            hidden: { $ne: true },
          },
          aggregation: [
            {
              $lookup: {
                from: "teachers",
                localField: "teacher",
                foreignField: "_id",
                as: "teacher",
              },
            },
            {
              $lookup: {
                from: "subjects",
                localField: "subject",
                foreignField: "_id",
                as: "subject",
              },
            },
            {
              $lookup: {
                from: "modules",
                localField: "module",
                foreignField: "_id",
                as: "module",
              },
            },
            {
              $lookup: {
                from: "moduleSpecifics",
                localField: "moduleSpecific",
                foreignField: "_id",
                as: "moduleSpecific",
              },
            },
            {
              $lookup: {
                from: "rooms",
                localField: "room",
                foreignField: "_id",
                as: "room",
              },
            },
            {
              $lookup: {
                from: "classrooms",
                localField: "classeRoom",
                foreignField: "_id",
                as: "classeRoom",
              },
            },
            {
              $project: {
                _id: 1,
                teacher: {
                  $arrayElemAt: ["$teacher.employee", 0],
                },
                classeRoom: {
                  $let: {
                    vars: {
                      classVar: {
                        $arrayElemAt: ["$classeRoom", 0],
                      },
                    },
                    in: {
                      name: "$$classVar.name",
                      _id: "$$classVar._id",
                    },
                  },
                },
                time: 1,
                day: 1,
                room: {
                  $let: {
                    vars: {
                      roomVar: {
                        $arrayElemAt: ["$room", 0],
                      },
                    },
                    in: {
                      name: "$$roomVar.name",
                      _id: "$$roomVar._id",
                    },
                  },
                },
                subject: {
                  $let: {
                    vars: {
                      subjectVar: {
                        $arrayElemAt: ["$subject", 0],
                      },
                    },
                    in: {
                      name: "$$subjectVar.name",
                      _id: "$$subjectVar._id",
                      status: "$$subjectVar.status",
                    },
                  },
                },
                module: {
                  $let: {
                    vars: {
                      moduleVar: {
                        $arrayElemAt: ["$module", 0],
                      },
                    },
                    in: {
                      name: "$$moduleVar.name",
                      _id: "$$moduleVar._id",
                      status: "$$moduleVar.status",
                    },
                  },
                },
                moduleSpecific: {
                  $let: {
                    vars: {
                      moduleSpecificVar: {
                        $arrayElemAt: ["$moduleSpecific", 0],
                      },
                    },
                    in: {
                      name: "$$moduleSpecificVar.name",
                      _id: "$$moduleSpecificVar._id",
                      status: "$$moduleSpecificVar.status",
                    },
                  },
                },
                ends: 1,
                duration: 1,
                type: 1,
              },
            },
          ],
        })
          .then(({ data }) => {
            data = data
              .map((d) => {
                if (!d.type) d.type = "S";
                return d;
              })
              .filter(
                (d) =>
                  (d.type != "S" || d.subject.status !== "inactive") &&
                  (d.type != "MP" || d.module.status !== "inactive") &&
                  (d.type != "MS" || d.module.status !== "inactive")
              );
            const day = new Date().getDay() - 1;
            data.forEach((l) => {
              const hours = Number(l.ends.split(":")[0] - l.time.split(":")[0]);
              totalHours.value += hours;
              if (day == l.day) today.value += hours;
              if ((day + 1) % 6 == l.day) tomorrow.value += hours;
            });
          })
          .catch((e) => console.log(e));

        getCoursesData();

        // get meetings
        ApiService.get("/teachers/meeting")
          .then(({ data }) => {
            meetings.value = data.filter(
              (meet) =>
                moment(meet.date).isoWeek == moment(new Date()).isoWeek &&
                new Date().getTime() - 1000 * 60 * 60 * 24 <
                  new Date(meet.date).getTime()
            );

            console.log(meetings.value);
          })
          .catch((e) => {
            console.log(e);
          });
      }

      if (hasAdvisorAccess.value) {
        await ApiService.get("/lms/advisor/homeworks")
          .then(({ data }) => {
            data.forEach((exercise: AdvisorExercise) => {
              advisorExercises.value.push(exercise);
            });
          })
          .catch((e) => console.log(e));

        ApiService.get("/lms/advisor/courses")
          .then(({ data }) => {
            data.forEach((course: AdvisorCourse) => {
              advisorCourses.value.push(course);
            });
          })
          .catch((e) => console.log(e))
          .finally(() => {
            loadedAdvisorCharts.value = true;
          });
      }
    });

    const getCoursesData = async () => {
      await ApiService.get("/lms/homework/list")
        .then(({ data }) => {
          exercises.value = data;
        })
        .catch((e) => console.log(e));

      await ApiService.get("/lms/course/list")
        .then(({ data }) => {
          courses.value = data;
          loadedCharts.value = true;
        })
        .catch((e) => console.log(e));
    };

    const buildingData = ref<{
      name: string;
      adress: string;
      phone: string;
      headMaster: string;
      logo: string;
      base_url: string;
    }>({
      name: "",
      adress: "",
      phone: "",
      headMaster: "",
      logo: "",
      base_url: "",
    });

    buildingData.value = store.getters.serverConfigUrl.building;

    const advisorSubjects = user.subjects.filter(
      (s) => s.status !== "inactive"
    );

    const searchItems = () => {
      filteredClassroomsData.value = classroomsData.value.filter(
        (item: any) =>
          item.classRoom && item.classRoom.name.includes(search.value)
      );
    };

    return {
      t,
      checked,
      classroomsData,
      filteredClassroomsData,
      totalHours,
      today,
      tomorrow,
      exercises,
      courses,
      loadedCharts,
      search,
      hasTeacherAccess,
      hasAdvisorAccess,
      loadedAdvisorCharts,
      advisorExercises,
      advisorCourses,
      buildingData,
      apiUrl,
      currentUser,
      advisorSubjects,
      meetings,
      moment,
      searchItems,
      loading,
    };
  },
});
